import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Progressor } from 'src/modules/utils/misc/Progressor';
import { Wrapper } from 'src/modules/utils/misc/wrapper.model';
import { DhTools } from '../../models/dh-tools.model';
import { TestBarcodeResultItem } from 'src/modules/enaio/shared/TestBarcodeResultItem';
import { Utils } from 'src/modules/utils/shared/utils';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { BarcodeRecognitionParams } from 'src/modules/enaio/shared/BarcodeRecognitionParams';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';

@Component({
  selector: 'app-barcode-tester',
  templateUrl: './barcode-tester.component.html',
  styleUrls: ['./barcode-tester.component.scss']
})
export class BarcodeTesterComponent extends ComponentView {

    form: FrontendFormDefinition;
    table: TableData;
    progressor = new Wrapper<Progressor>();

    async initParams(): Promise<boolean> {
        let defaultParams = `
            [
                {
                    "extensiveScan": false
                },
                {
                    "extensiveScan": true
                },
                {
                    "extensiveScan": false,
                    "scanDpi": 200
                },
                {
                    "extensiveScan": true,
                    "scanDpi": 200
                },
                {
                    "extensiveScan": false,
                    "scanDpi": 300
                },
                {
                    "extensiveScan": true,
                    "scanDpi": 300
                },
                {
                    "extensiveScan": false,
                    "scanDpi": 400
                },
                {
                    "extensiveScan": true,
                    "scanDpi": 400
                }
            ]
        `;

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("dir", "Verzeichnis mit PDF-Dateien oder PDF-Dateiname", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("dhStyle", "Dh-Stil", FrontendFieldType.checkBox, { onValueChanged: this.dhStyleChanged.bind(this) }),
            new FrontendFieldDefinition("params", "Erkennungsparameter", FrontendFieldType.textArea, { value: defaultParams, mandatory: true, fixedHeight: "600px", monospaced: true }),
            new FrontendFieldDefinition("paramsExtensiveScanNo", "Extensive Scan Nein", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsExtensiveScanYes", "Extensive Scan Ja", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsDpiEmpty", "DPI Leer", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsDpi200", "DPI 200", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsDpi300", "DPI 300", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsDpi400", "DPI 400", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsDpi200300", "DPI 200/300", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsLightMargin30", "Light Margin (Randabstand) 30 (Default)", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsLightMargin35", "Light Margin (Randabstand) 35", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsLightMargin40", "Light Margin (Randabstand) 40", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsLightMargin45", "Light Margin (Randabstand) 45", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("paramsLightMargin50", "Light Margin (Randabstand) 50", FrontendFieldType.checkBox, { value: true })
        ]);
        this.dhStyleChanged();
        return Promise.resolve(true);
    }

    dhStyleChanged(): void {
        let dhStyle = this.form.getValue("dhStyle") as boolean;
        this.form.getField("params").visible = dhStyle;
        this.form.getField("paramsExtensiveScanNo").visible = !dhStyle;
        this.form.getField("paramsExtensiveScanYes").visible = !dhStyle;
        this.form.getField("paramsDpiEmpty").visible = !dhStyle;
        this.form.getField("paramsDpi200").visible = !dhStyle;
        this.form.getField("paramsDpi300").visible = !dhStyle;
        this.form.getField("paramsDpi400").visible = !dhStyle;
        this.form.getField("paramsDpi200300").visible = !dhStyle;
        this.form.getField("paramsLightMargin30").visible = !dhStyle;
        this.form.getField("paramsLightMargin35").visible = !dhStyle;
        this.form.getField("paramsLightMargin40").visible = !dhStyle;
        this.form.getField("paramsLightMargin45").visible = !dhStyle;
        this.form.getField("paramsLightMargin50").visible = !dhStyle;
    }

    async test(): Promise<void> {
        let dhStyle = this.form.getValue("dhStyle") as boolean;
        let params: BarcodeRecognitionParams[];
        if (dhStyle) {
            params = Utils.fromPlainArray(BarcodeRecognitionParams, Utils.fromJson(this.form.getValue("params") as string) as any[]);
        }
        else {
            params = [];
            for (let scanDpi of Utils.arrayWithoutNull([this.form.getValue("paramsDpiEmpty") ? [0] : null, this.form.getValue("paramsDpi200") ? [200] : null, this.form.getValue("paramsDpi300") ? [300] : null, this.form.getValue("paramsDpi400") ? [400] : null, this.form.getValue("paramsDpi200300") ? [200, 300] : null])) {
                for (let lightMargin of Utils.arrayWithoutNull(Utils.getRange(0, 4).map(index => this.form.getValue("paramsLightMargin" + (30 + index * 5)) ? 30 + index * 5 : null))) {
                    for (let extensiveScan of Utils.arrayWithoutNull([this.form.getValue("paramsExtensiveScanNo") ? false : null, this.form.getValue("paramsExtensiveScanYes") ? true : null])) {
                        params.push(Utils.fromPlain(BarcodeRecognitionParams, {
                            extensiveScan,
                            scanDpi,
                            barcodeParams: {
                                LightMargin: lightMargin
                            }
                        }));
                    }
                }
            }
        }
        let paramsCount = params.length;

        if (paramsCount == 0) {
            await this.app.messageDialog.info("Es wurden keine Parameter angegeben");
            return;
        }

        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/tools/testBarcodes", { dir: this.form.getValue("dir") as string, params: Utils.toJson(params)}).getText(), r => {
            let result = Utils.fromPlainArray(TestBarcodeResultItem, Utils.fromJson(r.value) as any[]);
            this.table = new TableData([
                new TableColumn("fileName", "Dateiname"),
                new TableColumn("page", "Seite"),
                new TableColumn("barcode", "Barcode"),
                ...Utils.getRange(0, paramsCount - 1).map(index => new TableColumn("params" + index, (index + 1) + "(" + this.paramsToString(params[index]) + ")", TableCellType.yesNo))
            ], result.map(item => new TableRow(item, {
                fileName: item.fileName,
                page: item.page,
                barcode: item.value,
                ...Utils.arrayToPlainMap(Utils.getRange(0, paramsCount - 1), index => "params" + index, index => item.foundByParams.includes(index))
            } as OrdinaryObject)), [new TableSortColumn("fileName"), new TableSortColumn("page")]);
        });
    }

    private paramsToString(p: BarcodeRecognitionParams): string {
        return Utils.arrayItemsToString(Utils.arrayWithoutNull([
            p.extensiveScan ? "ext" : null,
            p.scanDpi != null && Utils.arrayGetSafe(p.scanDpi, 0) != 0 ? Utils.arrayItemsToString(p.scanDpi) : null,
            p.barcodeParams?.LightMargin > 0 ? "LM:" + p.barcodeParams.LightMargin : null
        ]), " ");
    }
}
