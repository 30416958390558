<div class="grid">
    <p-card *ngIf="nothingFound" header="Keine Kompetenzen gefunden">
        Es wurden keine Kompetenzen in enaio gefunden.
    </p-card>
</div>

<div class="grid">
    <p-card *ngIf="editingAllowed === false" class="col-12" header="Bearbeitung nicht freigegeben">
        <span style="color: red">Die Bearbeitung der Zeugnisse wurde durch den Administrator noch nicht freigegeben.</span>
    </p-card>
</div>

<div class="grid">
    <sm-toolbar class="col-12" *ngIf="loaded">
        <p-dropdown *ngIf="!isGradeCertificate" [styleClass]="'width300'" id="domain" [options]="domains"
            [(ngModel)]="selectedDomain" (onChange)="selectedDomainChanged()">
        </p-dropdown>
        <p-button icon="fas fa-eye" label="Vorschau" (onClick)="preview()" [disabled]="!editingAllowed">
        </p-button>
        <p-button icon="fas fa-cloud-upload-alt" label="Zeugnis erstellen" (onClick)="saveDocument()"></p-button>
        <p-button icon="fas fa-save" label="Speichern" (onClick)="saveClick(0)" [disabled]="!editingAllowed">
        </p-button>
        <p-button icon="fas fa-arrow-left" label="Speichern & Vorheriger Schüler" (onClick)="saveClick(-1)" [disabled]="!editingAllowed">
        </p-button>
        <p-button icon="fas fa-arrow-right" label="Speichern & Nächster Schüler" (onClick)="saveClick(1)" [disabled]="!editingAllowed">
        </p-button>
        <p-button *ngIf="lastYearCertId > 0" icon="fas fa-redo" label="Kompetenzauswahl aus Vorjahr übernehmen" (onClick)="copyFromLastYear()">
        </p-button>
        <p-button *ngIf="isAdmin" styleClass="p-button-warning" icon="fas fa-redo" label="Kompetenzen neu generieren" (onClick)="refresh()">
        </p-button>
    </sm-toolbar>
</div>
<div class="grid" [appDisable]="!editingAllowed">
    <p-table *ngIf="gradeBookSchoolClass != null" class="align-left col-6 col-offset-3" styleClass="p-datatable-gridlines" [value]="grades">
        <ng-template pTemplate="header">
            <tr>
                <th>Fach</th>
                <th>&Oslash;</th>
                <th>Endnote</th>
                <th>letzte Bearbeitung</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-grade>
            <tr *ngIf="grade.firstOfGroup">
                <td colspan="4"><b>{{grade.group}}</b></td>
            </tr>
            <tr>
                <td>{{grade.label}}</td>
                <td>{{grade.average}}</td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown id="certificateGrade" [editable]="false" [styleClass]="'w-full'" [options]="certificateGradeItems" [(ngModel)]="grade.o.grade">
                            </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{grade.o.grade}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>{{grade.o.getModifiedText()}}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-card *ngIf="loaded" header="{{selectedDomain}}" class="col-12">
        <p-table class="align-left" styleClass="p-datatable-gridlines" *ngVar="guiCertificate.domains.get(selectedDomain) as sel" [value]="sel.marksFlat">
            <ng-container *ngVar="sel.showTrend(pupil) as sr">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngIf="sel.hasLevel2" width="12%" [attr.rowspan]="sr ? 2 : 1">Kompetenzstufe 1</th>
                        <th *ngIf="sel.hasLevel2" width="13%" [attr.rowspan]="sr ? 2 : 1">Kompetenzstufe 2</th>
                        <th width="23%" [attr.rowspan]="sr ? 2 : 1">Grundkompetenz</th>
                        <th width="5%" [attr.rowspan]="sr ? 2 : 1">nicht be&shy;wer&shy;tet</th>
                        <th width="5%" [attr.rowspan]="sr ? 2 : 1">nicht be&shy;urteil&shy;bar</th>
                        <th *ngFor="let markName of markNames; index as index" width="5%" min-width="50px" [attr.rowspan]="sr ? 2 : 1" [innerHTML]="markName | keepHtml"></th>
                        <th *ngIf="sel.domain != 'Kompetenzentwicklung'" width="20%" [attr.rowspan]="sr ? 2 : 1">
                            {{!sr ? 'Bemerkungen' : 'Bemerkungen / sonderpäd. Maßnahmen'}}
                        </th>
                        <th width="15%" [attr.rowspan]="sr ? 2 : 1">letzte Bearbeitung</th>
                        <th *ngIf="sr" width="15%" [attr.colspan]="5">Lernentwicklung</th>
                    </tr>
                    <tr *ngIf="sr">
                        <th width="3%">-</th>
                        <th width="3%">&#x2B07;</th>
                        <th width="3%">&#x2198;</th>
                        <th width="3%">&#x2197;</th>
                        <th width="3%">&#x2B06;</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-mark>
                    <tr *ngIf="mark.level1Span > 0 && !sel.hasLevel2">
                        <td [attr.colspan]="5 + markNames.length + (sr ? 5 : 0)" style="background-color: #FABF8E"><p-triStateCheckbox *ngIf="pupil.getSchoolType() === _SchoolType.special" [style.margin-right.px]="10" [ngModel]="getLevel1Hidden(mark)" (onChange)="level1VisibilityChanged($event, mark)" binary="true"></p-triStateCheckbox>{{mark.level1}}
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="mark.level1Span > 0 && sel.hasLevel2" [attr.rowspan]="mark.level1Span"
                            [style.background-color]="'#FABF8E'"><p-triStateCheckbox *ngIf="pupil.getSchoolType() === _SchoolType.special" [style.margin-right.px]="10" [ngModel]="getLevel1Hidden(mark)" (onChange)="level1VisibilityChanged($event, mark)" binary="true"></p-triStateCheckbox>{{mark.level1}}</td>
                        <td *ngIf="mark.level2Span > 0 && sel.hasLevel2" [attr.rowspan]="mark.level2Span"
                            [style.background-color]="'#ffffff'"><p-triStateCheckbox *ngIf="pupil.getSchoolType() === _SchoolType.special" [style.margin-right.px]="10" [ngModel]="getLevel2Hidden(mark)" (onChange)="level2VisibilityChanged($event, mark)" binary="true"></p-triStateCheckbox>{{mark.level2}}</td>
                        <td> <p-checkbox  *ngIf="pupil.getSchoolType() === _SchoolType.special" [style.margin-right.px]="10" [(ngModel)]="!mark.hidden" (onChange)="markVisibilityChanged($event, mark)" [binary]="true"></p-checkbox>{{mark.title}}
                        </td>
                        <td>
                            <p-radioButton name="{{mark.competencyId}}_mark" value="0" [(ngModel)]="mark.mark" (ngModelChange)="somethingChanged()">
                            </p-radioButton>
                        </td>
                        <td>
                            <p-radioButton name="{{mark.competencyId}}_mark" value="99" [(ngModel)]="mark.mark" (ngModelChange)="somethingChanged()">
                            </p-radioButton>
                        </td>
                        <td *ngFor="let markName of markNames; index as index">
                            <p-radioButton name="{{mark.competencyId}}_mark" [value]="index + 1" [(ngModel)]="mark.mark" (ngModelChange)="somethingChanged()">
                            </p-radioButton>
                        </td>
                        <td  *ngIf="sel.domain != 'Kompetenzentwicklung'" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <textarea pInputTextArea spellcheck=false type="text" autoResize="autoResize"
                                        [(ngModel)]="mark.note" (ngModelChange)="somethingChanged()"></textarea>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{mark.note}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>{{mark.assignedBy}}
                            {{mark.assignedDate != null ? (mark.assignedDate | date: 'dd.MM.yy HH:mm') : ''}}</td>
                        <td *ngIf="sr">
                            <p-radioButton name="{{mark.competencyId}}_trend" value="0" (ngModelChange)="somethingChanged()"
                                [(ngModel)]="mark.trend">
                            </p-radioButton>
                        </td>
                        <td *ngIf="sr">
                            <p-radioButton name="{{mark.competencyId}}_trend" value="1" (ngModelChange)="somethingChanged()"
                                [(ngModel)]="mark.trend">
                            </p-radioButton>
                        </td>
                        <td *ngIf="sr">
                            <p-radioButton name="{{mark.competencyId}}_trend" value="2" (ngModelChange)="somethingChanged()"
                                [(ngModel)]="mark.trend">
                            </p-radioButton>
                        </td>
                        <td *ngIf="sr">
                            <p-radioButton name="{{mark.competencyId}}_trend" value="3" (ngModelChange)="somethingChanged()"
                                [(ngModel)]="mark.trend">
                            </p-radioButton>
                        </td>
                        <td *ngIf="sr">
                            <p-radioButton name="{{mark.competencyId}}_trend" value="4" (ngModelChange)="somethingChanged()"
                                [(ngModel)]="mark.trend">
                            </p-radioButton>
                        </td>
                    </tr>
                </ng-template>
            </ng-container>
        </p-table>

        <div *ngIf="guiCertificate.domains.get(selectedDomain).averageGrade >= 0" class="grid" style="margin-top:20px">
            <label class="labelx" for="teacher">Durchschnittsnote aus Notenbuch:</label>
            <input class="inputx" style="width: 300px" id="teacher" type="text" pInputText [disabled]=true
                [(ngModel)]="guiCertificate.domains.get(selectedDomain).averageGrade" />
        </div>
        <div class="grid" style="margin-top:20px">
            <label class="labelx" for="teacher">Bewertung durch Fachlehrer:</label>
            <input *ngIf="!addTeacherNameForDomains.includes(selectedDomain)" class="inputx" style="width: 300px" id="teacher" type="text" pInputText
                [(ngModel)]="guiCertificate.domains.get(selectedDomain).teacher" />
            <ng-container *ngIf="addTeacherNameForDomains.includes(selectedDomain)">
                <input class="inputx" style="width: 300px" id="teacher" type="text" pInputText readonly [value]="getThisTeacherName()"/>
                <p-message class="ml-3" severity="info" text="(Automatisch gesetzt aufgrund vorgenommener Änderungen)"></p-message>
            </ng-container>
        </div>
        <div class="grid" style="margin-top:20px">
            <label class="labelx" for="topics">Bemerkungen:</label>
            <textarea pInputTextArea spellcheck=false class="inputx" style="width: 800px; height: 200px" id="topics" type="text"
                [rows]="5" [(ngModel)]="guiCertificate.domains.get(selectedDomain).topics"></textarea>
        </div>
    </p-card>
</div>
