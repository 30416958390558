import { FrontendFieldListItem } from "src/modules/sm-base/models/frontend-field-list-item.model";
import { TableCellType } from "src/modules/sm-base/models/table-cell-type.enum";
import { TableColumn } from "src/modules/sm-base/models/table-column.model";
import { Utils } from "src/modules/utils/shared/utils";
import { EnaioGuiFieldListItem } from "../shared/EnaioGuiFieldListItem";
import { EnaioGuiFieldTableColumn } from "../shared/EnaioGuiFieldTableColumn";
import { EnaioWorkflowDataMember } from "../shared/EnaioWorkflowDataMember";
import { EnaioWorkflowBasicDataType } from "../shared/EnaioWorkflowBasicDataType";
import { FrontendFieldDefinition } from "src/modules/sm-base/models/frontend-field-definition.model";
import { FrontendFieldType } from "src/modules/sm-base/models/frontend-field-type.enum";

export class EnaioUtils {

    static CLIENT_ID_ENAIO = "DB928B6631304CFC8D30B1B4FE67679B";
    static CLIENT_ID_ENAIO_WEB = "D4C24904B784420C922B66E012624D42";

    static CLIENT_ID_ENAIO_FRENCH = "DB928B6631304CFC8D30B1B4FE676888";
    static CLIENT_ID_ENAIO_ENGLISH = "DB928B6631304CFC8D30B1B4FE676999";

    static CLIENT_ID_ENAIO_WEB_FRENCH = "D4C24904B784420C922B66E012624888";
    static CLIENT_ID_ENAIO_WEB_ENGLISH = "D4C24904B784420C922B66E012624999";

    static CLIENT_ID_ENAIO_MOBILE = "4386E0A06D4141C6B3CC367B8A7B7C9F";
    static CLIENT_ID_ENAIO_USER_DEFINED = "B0EAA69765BC4689B954439C4B2D3EAF";

    static CLIENT_ID_SERVER = "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";

    static getClientTypeName(clientTypeId: string): string {
        switch (clientTypeId) {
            case EnaioUtils.CLIENT_ID_ENAIO:
                return "";
            case EnaioUtils.CLIENT_ID_ENAIO_WEB:
                return "WebClient";
            case EnaioUtils.CLIENT_ID_ENAIO_FRENCH:
                return "[Französisch]";
            case EnaioUtils.CLIENT_ID_ENAIO_ENGLISH:
                return "[Englisch]";
            case EnaioUtils.CLIENT_ID_ENAIO_WEB_FRENCH:
                return "WebClient [Französisch]";
            case EnaioUtils.CLIENT_ID_ENAIO_WEB_ENGLISH:
                return "WebClient [Englisch]";
            case EnaioUtils.CLIENT_ID_ENAIO_MOBILE:
                return "Mobil";
            case EnaioUtils.CLIENT_ID_ENAIO_USER_DEFINED:
                return "Benutzerdefiniert";
            case EnaioUtils.CLIENT_ID_SERVER:
                return "Server";
            default:
                return clientTypeId;
        }
    }

    static isClientTypeRelevant(clientTypeId: string): boolean {
        return clientTypeId == EnaioUtils.CLIENT_ID_ENAIO || clientTypeId == EnaioUtils.CLIENT_ID_ENAIO_WEB || clientTypeId == EnaioUtils.CLIENT_ID_SERVER;
    }

    static guiFieldListItemToFrontend(enaioItem: EnaioGuiFieldListItem, useItemValue: boolean): FrontendFieldListItem {
        return new FrontendFieldListItem(useItemValue ? enaioItem.value : enaioItem.text, enaioItem.text);
    }

    static guiFieldTableColumnToFrontend(enaioItem: EnaioGuiFieldTableColumn, recordMember: EnaioWorkflowDataMember, fullWidth: number): TableColumn {
       // console.log(enaioItem);
       // console.log(recordMember);
        return new TableColumn(
            recordMember != null ? recordMember.name : enaioItem.name,
            enaioItem != null ? enaioItem.name : recordMember.name,
            recordMember?.dataType.basic == EnaioWorkflowBasicDataType.date ? TableCellType.date :
            recordMember?.dataType.basic == EnaioWorkflowBasicDataType.integer ? TableCellType.number :
            TableCellType.text,
            {
                width: Utils.toString(enaioItem != null ? enaioItem.colWidth * 100 / fullWidth : 10) + "px",
                showNull: false,
                editor: Utils.isNoe(enaioItem?.listItems) ? null : new FrontendFieldDefinition("field", "Feld", FrontendFieldType.comboBox, { dropdownEditable: false, listItems: enaioItem.listItems.map(li => new FrontendFieldListItem(li, Utils.toString(li))) })
            });
    }
}
