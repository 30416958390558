import { MainAppService } from "src/modules/app-template/services/main-app.service";
import { EnaioGuiField } from "src/modules/enaio/shared/EnaioGuiField";
import { EnaioWorkItemParameter } from "src/modules/enaio/shared/EnaioWorkItemParameter";
import { EnaioWorkflowBasicDataType } from "src/modules/enaio/shared/EnaioWorkflowBasicDataType";
import { PrimeMessageSeverity } from "src/modules/sm-base/models/prime-message-severity.model";
import { RestEndpoint } from "src/modules/sm-base/models/rest-endpoint.model";
import { FrontendFieldDefinition } from "src/modules/sm-base/models/frontend-field-definition.model";
import { FrontendFieldListItem } from "src/modules/sm-base/models/frontend-field-list-item.model";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { WorkflowForm } from "./workflow-form.model";

export class FormHelper {

    dms = new FormHelperDms();

    constructor (private app: MainAppService, private form: WorkflowForm) {
    }

    addFile(_osid: number, _objectTypeId?: number, _fileArea?: string, _tryLocation?: number): void {
        throw new Error("Die Funktion addFile ist noch nicht implementiert");
    }

    getFields(): WfImplMaskField[] {
        return this.form.workItem.masks[0].fields.map(f => this.getFieldByName(f.name));
    }

    getFieldByName(name: string): WfImplMaskField {
        let field = this.form.workItem.masks[0].fields.find(f => f.name == name);
        return field != null ? new WfImplMaskField(field, this.form.form.getField(field.wfFieldId)) : null;
    }

    getFieldByParameterName(name: string): WfImplMaskField {
        let param = this.form.workItem.parameters.find(p => p.name == name);
        if (param == null) {
            return null;
        }
        let field = this.form.workItem.masks[0].fields.find(f => param.formFieldId === f.wfFieldId);
        return field != null ? new WfImplMaskField(field, this.form.form.getField(field.wfFieldId)) : null;
    }

    getInfo(): OrdinaryObject {
        return {
            user: {
                name: this.app.getUserName()
            },
            workflow: {
                name: this.form.workItem.name,
                activityId: this.form.workItem.activityId,
                workflowId: this.form.workItem.workflowId,
                processId: this.form.workItem.processId,
                workflowType: 1,
                rActivityId: this.form.workItem.id
            }
        };
    }

    getParameters(): WfImplParam[] {
        return this.form.workItem.parameters.map(p => this.getParameterByName(p.name));
    }

    getParameterByName(name: string): WfImplParam {
        let param = this.form.workItem.parameters.find(p => p.name == name);
        return new WfImplParam(param, this.getFieldByParameterName(param.name));
    }

    async includePublicScript(url: string): Promise<void> {
        (window as any).clientScriptFunctions = this.form.clientScriptFunctions;
        let id = "clientScriptPublic_0_";

        let code = await RestEndpoint.main().query({url}).run("api/wfl/formHelper/includePublicScript").getString();
        code = "window.clientScriptFunctions['" + id + "'] = (function anonymous(formHelper,globals,scriptingStorage) {\nadditionParameter = [window.formHelper];" + code + "\n})";
        this.form.loadScript(id, code);

        delete (window as any).clientScriptFunctions;

        await this.form.runScriptByName("clientScriptPublic_0_");
    }

    showToast(severity: PrimeMessageSeverity, detail: string, summary: string, duration?: number): void {
        this.app.showToast(severity, summary, detail, duration);
    }
}

export class FormHelperDms {

    async search(query: string): Promise<OrdinaryObject[]> {
        return Utils.fromJson(await RestEndpoint.main().query({query: Utils.toJson(query)}).run("api/wfl/formHelper/dmsSearch").getString()) as OrdinaryObject[];
    }
}

export class WfImplMaskField {

    api: WfImplMaskFieldApi;
    model: WfImplMaskFieldModel;

    constructor(private field: EnaioGuiField, private guiField: FrontendFieldDefinition) {
        this.api = new WfImplMaskFieldApi(this, field, guiField);
        this.model = new WfImplMaskFieldModel(this, field, guiField);
    }
}

export class WfImplMaskFieldApi {

    constructor (private internal: WfImplMaskField, private field: EnaioGuiField, private guiField: FrontendFieldDefinition) {
    }

    disable(): void {
        this.guiField.enabled = false;
    }

    enable(): void {
        this.guiField.enabled = true;
    }

    getRequired(): boolean {
        return this.guiField.mandatory;
    }

    getValue(): any {
        return this.guiField.value;
    }

    hide(): void {
        this.guiField.visible = false;
    }

    setCustomListEntries(items: any[]): void {
        this.guiField.listItems = items.map(item => new FrontendFieldListItem(item, Utils.toString(Utils.hasProperty(item, "value") ? item.value : item)));
    }

    setInvalid(title?: string, message?: string): void {
        this.guiField.errorMessage = Utils.stringDef(message, "Ungültige Eingabe");
        this.guiField.errorMessageFromScript = this.guiField.errorMessage;
    }

    setRequired(value: boolean): void {
        this.guiField.mandatory = value;
    }

    setTitle(title: string): void {
        this.guiField.caption = title;
    }

    setValid(): void {
        this.guiField.errorMessage = null;
        this.guiField.errorMessageFromScript = null;
    }

    setValue(value: any): void {
        this.guiField.value = value;
    }

    show(): void {
        this.guiField.visible = true;
    }

    getRows(): any[] {
        return this.guiField.value;
    }

    getCellValue(row: number, column: number): any {
        let result = this.guiField.value[row][this.guiField.tableColumns[column].id];
        if (result instanceof Date) {
            result = Utils.dateFormatDefaultDate(result);
        }
        return result;
    }

    setCellValue(row: number, column: number, value: any): void {
       this.guiField.value[row][this.guiField.tableColumns[column].id] = value;
    }

    disableCell(row: number, column: number): void {
        this.guiField.tableDisabledCells ??= [];
        let value = {row, column};
        if (!this.guiField.tableDisabledCells.some(cell => cell.row == row && cell.column == column)) {
            this.guiField.tableDisabledCells = [...this.guiField.tableDisabledCells, value];
        }
    }

    setCellValid(row: number, column: number): void {
        this.guiField.setCellValid(row, column, true);
    }

    setCellInvalid(row: number, column: number, errorMessage: string): void {
        this.guiField.setCellValid(row, column, false, errorMessage);
    }

}

export class WfImplMaskFieldModel {

    internal: string;
    name: string;

    constructor (private impl: WfImplMaskField, private field: EnaioGuiField, private guiField: FrontendFieldDefinition) {
        this.internal = field.internalName;
        this.name = field.name;
    }
}

export class WfImplParam {

    api: WfImplParamApi;
    model: WfImplParamModel;

    constructor(private field: EnaioWorkItemParameter, private maskField: WfImplMaskField) {
        this.api = new WfImplParamApi(this, field, maskField);
        this.model = new WfImplParamModel(this, field, maskField);
    }
}

export class WfImplParamApi {
    constructor (private internal: WfImplParam, private field: EnaioWorkItemParameter, private maskField: WfImplMaskField) {
    }

    getValue(): any {
        if ((this.field.value?.dataType?.basic == EnaioWorkflowBasicDataType.date || this.field.value?.dataType?.basic == EnaioWorkflowBasicDataType.dateTime) && this.field.value?.value == null) {
            return "";
        }
        else if (this.field.value?.value instanceof Date) {
            return Utils.dateFormatDefaultDate(this.field.value.value);
        }
        else {
            let value = this.field.value?.value;
            return Utils.isNumber(value) ? Utils.toString(value) : value;
        }
    }

    setValue(value: any): void {
        this.field.value.value = value;
        if (this.maskField != null) {
            this.maskField.api.setValue(value);
        }
    }
}

export class WfImplParamModel {

    name = "";

    constructor (private internal: WfImplParam, private field: EnaioWorkItemParameter, private maskField: WfImplMaskField) {
        this.name = field.name;
    }
}
