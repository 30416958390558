<ng-container *ngIf="inited">
    <sm-toolbar>
        <p-button [disabled]="!isChanged()" icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
    </sm-toolbar>
    <p-table [value]="rows" [styleClass]="'p-datatable-gridlines'">
    <ng-template pTemplate="header">
        <tr>
        <th class="align-left" width="30%">Klassenamt</th>
        <th class="align-left" width="10%">Ist Schüler</th>
        <th class="align-left" width="30%">Vorsitz</th>
        <th class="align-left" width="30%">Stellvertreter</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-index="rowIndex">
        <tr>
            <td class="align-left">{{row.type}}</td>
            <td>
                <p-inputSwitch (onChange)="changeIsPupil($event, row.type)" [(ngModel)]="isPupil[row.type]"></p-inputSwitch>
            </td>
            <td pEditableColumn>
                <p-cellEditor>
                <ng-template pTemplate="input">
                    <p-dropdown *ngIf="isPupil[row.type]" [filter]="true" id="main" [editable]="false" [styleClass]="'w-full'" [options]="pupilsGui" [ngModel]="row.main" (ngModelChange)="changeMain($event, row)">
                    </p-dropdown>
                    <input type="text" *ngIf="!isPupil[row.type]" class="w-full" pInputText [(ngModel)]="row.mainText"/>
                </ng-template>
                <ng-template pTemplate="output">
                    {{ row.main != 0 ? getPupil(row.main) : (row.mainText != "" ? row.mainText : '') }}
                </ng-template>
            </p-cellEditor>
            </td>
            <td pEditableColumn>
                <p-cellEditor>
                <ng-template pTemplate="input">
                    <p-multiSelect *ngIf="isPupil[row.type]" id="substitutes" appendTo="body" selectionMode="multiple" [styleClass]="'w-full'" [options]="pupilsGuiSubstitute" [ngModel]="row.substitutes" (ngModelChange)="changeSubstitutes($event, row)">
                    </p-multiSelect>
                    <input type="text" *ngIf="!isPupil[row.type]" class="w-full" pInputText [(ngModel)]="row.substitutesText"/>
                </ng-template>
                <ng-template pTemplate="output">
                    {{row.substitutes != null && row.substitutes.length > 0 ? getPupilList(row.substitutes).join(', ') : (row.substitutesText != "" ? row.substitutesText : '')}}
                </ng-template>
            </p-cellEditor>
            </td>
        </tr>
    </ng-template>
    </p-table>
</ng-container>
