import { Component, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { InputNumber } from 'primeng/inputnumber';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { InsertionPointDirective } from '../../directives/insertion-point.directive';
import { FrontendFieldDefinition } from '../../models/frontend-field-definition.model';
import { FrontendFieldType } from '../../models/frontend-field-type.enum';
import { FrontendFormDefinition } from '../../models/frontend-form-definition.model';

@Component({
    selector: 'app-sm-form-field-internal',
    templateUrl: './sm-form-field-internal.component.html',
    styleUrls: ['./sm-form-field-internal.component.scss']
})
export class SmFormFieldInternalComponent {

    _FrontendFieldType = FrontendFieldType;
    _Utils = Utils;

    @Output()
    onClick = new EventEmitter<FrontendFieldDefinition>();

    private _field: FrontendFieldDefinition = null;
    private valueBeforeEdit: any;

    autoCompleteSuggestions = [];

    @ViewChild(InsertionPointDirective, {static: true}) insertionPoint: InsertionPointDirective;

    @ViewChild("componentInputNumber") componentInputNumber: InputNumber;


    constructor(private app: MainAppService) {
    }

    @Input()
    set field(value: FrontendFieldDefinition) {
        this._field = value;

        if (this.field.type == FrontendFieldType.custom) {
            this._field.component = this.app.createComponent(this.insertionPoint, this._field.componentType as Type<any>);
            this._field.component.value = this._field.value;
            this._field.component.setContext(this._field.context);
        }
    }

    get field(): FrontendFieldDefinition {
        return this._field;
    }

    buttonClick(event: MouseEvent): void {
        //Aus unbekannten Gründen wird dieser Eventhandler auch aufgerufen, wenn in einem beliebigen Formfeld Enter gedrückt wird.
        //Dann sind screenX und screenY = 0. Solche "Klicks" werden ignoriert.
        if (event.screenX === 0 && event.screenY === 0) {
            return;
        }
        if (this._field.onClick != null) {
            this._field.onClick(event);
        }
    }

    valueChanged(): void {
        if (this._field.onValueChanged != null) {
            this._field.onValueChanged();
        }
    }

    addTableRow(): void {
        this._field.value = [...(this._field.value as object[]), this._field.generateEmptyTableItem()];
        this.valueChanged();
    }

    deleteTableRow(rowIndex: number): void {
        (this._field.value as object[]).splice(rowIndex, 1);
        this.valueChanged();
        //Das wird benötigt, um die Change Detection zu triggern. Sonst stimmen Breiten von Header und Zeilen nicht überein
        this._field.value = [...(this._field.value as object[])];
    }

    async editTableRow(rowIndex: number): Promise<void> {
        let form = new FrontendFormDefinition(this._field.tableColumns.map(col => col.toFrontendField()));
        form.fill((this._field.value as object[])[rowIndex]);
        await this.app.messageDialog.form(form, "Zeile editieren");
    }

    tableEditInit(): void {
        this.valueBeforeEdit = Utils.cloneDeep(this.field.value);
    }
    tableEditComplete(): void {
        if (this._field.onValueChanged != null && !Utils.equalsDeep(this.valueBeforeEdit, this.field.value)) {
            this._field.onValueChanged();
        }
    }

    focus(): void {
        this.componentInputNumber.el.nativeElement.focus();
    }

    autoComplete(event: any): void {
        this.autoCompleteSuggestions = this.field.listItems.filter(li => li.label != null && Utils.stringIncludesCi(li.label, event.query as string));
    }

    getColorPalette(): string[] {
        return this.field.predefinedColors.map(c => Utils.toColorHexString(c));
    }

    colorChanged(color: string): void {
        this.field.value = Utils.toColorNumber(color);
    }

}
